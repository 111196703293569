<template>
  <div class="main">
    <div class="manage-paymant content-body">
      <a-breadcrumb>
        <a-breadcrumb-item href="">
          <HomeOutlined />
        </a-breadcrumb-item>
        <a-breadcrumb-item href="">
          <span>Dashboard</span>
        </a-breadcrumb-item>
        <a-breadcrumb-item> Manage Payments </a-breadcrumb-item>
      </a-breadcrumb>
      <div class="plan-list">
        <ListPlan />
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import { HomeOutlined } from '@ant-design/icons-vue'

import ListPlan from '@/components/agency/payment/ListPlans.vue'

export default defineComponent({
  components: {
    HomeOutlined,
    ListPlan
  },
  setup () {
    return {
    }
  }
})
</script>

<style lang="scss">
  .plan-list {
    margin-top: 30px;
  }
</style>
