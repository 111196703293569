
import { defineComponent } from 'vue'
import { HomeOutlined } from '@ant-design/icons-vue'

import ListPlan from '@/components/agency/payment/ListPlans.vue'

export default defineComponent({
  components: {
    HomeOutlined,
    ListPlan
  },
  setup () {
    return {
    }
  }
})
